import { Component, OnInit } from '@angular/core';
import { SpcService } from 'src/app/services/spc.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-spc',
  templateUrl: './spc.component.html',
  styleUrls: ['./spc.component.sass']
})
export class SpcComponent implements OnInit {

  constructor(private spc:SpcService, private route:ActivatedRoute) { }

  data;

  ngOnInit() {
    if(this.route.snapshot.queryParamMap.get("id")){
      this.spc.search(this.route.snapshot.queryParamMap.get("id")).subscribe(data => {
        this.data = JSON.parse(JSON.stringify(data))          
        if(this.data.consumidor.consumidorcontato.length == undefined){
          let contato = this.data.consumidor.consumidorcontato
          this.data.consumidor.consumidorcontato = [contato]
        }

        if(this.data.registrosspc){
          if(this.data.registrosspc.length == undefined){
            let registros = this.data.registrosspc
            this.data.registrosspc = [registros]
          }
          if(this.data.registrosspc.length == 1){
            this.data.totalregistrosspc = this.data.registrosspc[0].valor
          }else{
            this.data.totalregistrosspc = this.data.registrosspc.reduce((total, registro) => {
              if(typeof total == 'number'){
                return total + Number(registro.valor)
              }else{
                return Number(total.valor) + Number(registro.valor)
              }
            })
          }
        }
        if(this.data.registroscheque){
          if(this.data.registroscheque.length == undefined){
            let registros = this.data.registroscheque
            this.data.registroscheque = [registros]
          }
          if(this.data.registroscheque.length == 1){
            this.data.totalregistroscheque = this.data.registroscheque[0].valorregistro
          }else{
  
            this.data.totalregistroscheque = this.data.registroscheque.reduce((total, registro) => {
              if(typeof total == 'number'){
                return total + Number(registro.valorregistro)
              }else{
                return Number(total.valorregistro) + Number(registro.valorregistro)
              }
            })
          }
        }


        console.log(this.data);
        
      },
      (err)=>{
        alert("Erro")
        console.error(err);
      })
    }else{
      alert('Id da consulta não informado!')
    }
  }

}
