import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
@Injectable({
  providedIn: 'root'
})
export class SpcService {

constructor(private http:HttpClient) { }

  search(id:String){
    return this.http.post('api/spc/visualizar',{
      id:id
    })
  }

}
